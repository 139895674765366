import { createSlice } from '@reduxjs/toolkit'

const { actions, reducer } = createSlice({
  name: 'App',
  initialState: {
  },
  reducers: {

  }
})

// export const {

// } = actions

export default reducer
