// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bulk-order-enquiry-js": () => import("./../../../src/pages/bulk-order-enquiry.js" /* webpackChunkName: "component---src-pages-bulk-order-enquiry-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-add-reviews-js": () => import("./../../../src/pages/my/add-reviews.js" /* webpackChunkName: "component---src-pages-my-add-reviews-js" */),
  "component---src-pages-my-addresses-billing-js": () => import("./../../../src/pages/my/addresses/billing.js" /* webpackChunkName: "component---src-pages-my-addresses-billing-js" */),
  "component---src-pages-my-addresses-js": () => import("./../../../src/pages/my/addresses.js" /* webpackChunkName: "component---src-pages-my-addresses-js" */),
  "component---src-pages-my-addresses-shipping-js": () => import("./../../../src/pages/my/addresses/shipping.js" /* webpackChunkName: "component---src-pages-my-addresses-shipping-js" */),
  "component---src-pages-my-orders-combined-js": () => import("./../../../src/pages/my-orders-combined.js" /* webpackChunkName: "component---src-pages-my-orders-combined-js" */),
  "component---src-pages-my-orders-js": () => import("./../../../src/pages/my-orders.js" /* webpackChunkName: "component---src-pages-my-orders-js" */),
  "component---src-pages-my-profile-edit-js": () => import("./../../../src/pages/my/profile/edit.js" /* webpackChunkName: "component---src-pages-my-profile-edit-js" */),
  "component---src-pages-my-profile-js": () => import("./../../../src/pages/my-profile.js" /* webpackChunkName: "component---src-pages-my-profile-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order-details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-orderfailed-js": () => import("./../../../src/pages/orderfailed.js" /* webpackChunkName: "component---src-pages-orderfailed-js" */),
  "component---src-pages-ordersuccess-js": () => import("./../../../src/pages/ordersuccess.js" /* webpackChunkName: "component---src-pages-ordersuccess-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-return-and-replacement-js": () => import("./../../../src/pages/return-and-replacement.js" /* webpackChunkName: "component---src-pages-return-and-replacement-js" */),
  "component---src-pages-shipping-delivery-js": () => import("./../../../src/pages/shipping-&-delivery.js" /* webpackChunkName: "component---src-pages-shipping-delivery-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */)
}

